import { Center, Container, Stack, Text, useMantineTheme } from '@mantine/core';

import TimelineView from 'views/Timeline-view';

function App() {
  const theme = useMantineTheme();
  const url = new URL(window.location.href);
  const customerId = url.searchParams.get('customerId') || '';
  const referenceId = url.searchParams.get('referenceId') || '';
  const token = url.searchParams.get('token') || '';

  return (
    <Container size="lg" p="lg">
      <Stack align="center">
        <Center p="md">
          <Text
            size="xl"
            style={{
              fontSize: '4rem',
              color: theme.colors.blue[5],
              textAlign: 'center',
            }}
          >
            Timeline
          </Text>
        </Center>
        <Center ml="xl">
          <TimelineView
            customerId={customerId}
            referenceId={referenceId}
            token={token}
          />
        </Center>
      </Stack>
    </Container>
  );
}

export default App;
