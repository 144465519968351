import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

import { Text, Group, ActionIcon, Space } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconCopy } from '@tabler/icons';

import { ITimelineEventItem } from 'types/timeline-types';

const formatTimestamp = (timestamp: string) => {
  const parsedDate = dateFns.parse(timestamp, 'yyyyMMdd:HHmmssSSS', new Date());
  return dateFnsTz.format(parsedDate,'MMM dd, yyyy hh:mm:ss', { timeZone: 'Europe/Amsterdam' });
};

interface IProps {
  eventDetails: ITimelineEventItem;
}

const TimelineEventItem = (props: IProps) => {
  const { eventDetails } = props;
  const { eventMessage, timestamp, eventType } = eventDetails;
  const clipboard = useClipboard({ timeout: 500 });
  return (
    <>
      <div style={{display: 'flex'}}>
       <Text size="md" color="dimmed" style={{marginRight: '3px'}}>{eventType}:</Text>
       <Text size="md" color="dimmed" dangerouslySetInnerHTML={{ __html: eventMessage}}></Text>
      </div>
      <Group spacing={'xs'} align={'center'}>
        <Text size="md" color="blue">
          {formatTimestamp(timestamp)}
        </Text>
        <ActionIcon
          color={clipboard.copied ? 'green' : 'indigo'}
          size="md"
          radius="md"
          variant="light"
          onClick={() => clipboard.copy(JSON.stringify(eventDetails))}
        >
          <IconCopy size={16} />
        </ActionIcon>
      </Group>
    </>
  );
};

export default TimelineEventItem;
