import * as dateFns from 'date-fns';

import { ITimeline } from 'types/timeline-types';
import { API_URL } from 'utils/constants';

export const getTimelineEvents = (
  { customerId, referenceId, token } = {
    customerId: '4',
    referenceId: '3',
    token: '00000000-0000-0000-0000-000000000000',
  },
) => {
  return fetch(
    `${API_URL}/timeline?referenceId=${referenceId}&customerId=${customerId}&token=${token}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );
};
export const sortTimelineEventsDesc = (
  timelineEvents: ITimeline[],
): ITimeline[] => {
  return timelineEvents.sort((eventA, eventB) => {
    return dateFns.compareDesc(
      dateFns.parse(
        eventA.metadata.timestamp,
        'yyyyMMdd:HHmmssSSS',
        new Date(),
      ),
      dateFns.parse(
        eventB.metadata.timestamp,
        'yyyyMMdd:HHmmssSSS',
        new Date(),
      ),
    );
  });
};
