import { Timeline as MantineTimeline } from '@mantine/core';

import { ITimeline } from 'types/timeline-types';
import TimelineEventItem from './TimelineEventItem';

interface IProps {
  events: ITimeline[];
}

const Timeline = ({ events }: IProps) => {
  console.log(events);
  return (
    <MantineTimeline bulletSize={30} lineWidth={5}>
      {events.map(({ name, metadata }: ITimeline) => (
        <MantineTimeline.Item key={name} title={metadata.eventName}>
          <TimelineEventItem eventDetails={metadata} />
        </MantineTimeline.Item>
      ))}
    </MantineTimeline>
  );
};

export default Timeline;
